<template>
  <div class="animated fadeIn">
    <DataViewWrapper
      ref="dataView"
      :filters="filters"
      :table-columns="tableColumns"
      :model="model"
      :slots="['Product']"
    >
      <div slot="Product" slot-scope="props">
        <b-link @click="viewItem(props.row)">
          {{ props.row['Product'] }}
        </b-link>
      </div>
    </DataViewWrapper>
  </div>
</template>

<script>
import models from '@/models'

export default {
  name: 'AvailableProductsTotals',
  components: {},
  data: function () {
    return {
      model: models.warehouse.availableProductsTotals,
      filters: [
        'Finished Product',
        'Warehouse',
        'Warehouse Status',
        'Organic Status',
        'Bottle Size',
        'Date Bottled',
        'Shipped Before',
        'Shipped After'
      ],
      tableColumns: [
        'ID',
        'Product',
        'Organic Status',
        'Bottles Remaining',
        'Bottles Calc',
        'Total Bottles',
        'Used Bottles',
        'Loss Bottles',
        'ABV',
        'Cases',
        'Most Recent',
        'Bottle Size',
        'Min Invoice Date',
        'Max Invoice Date',
        'Error',
        'Warehouse',
        'Excise tax',
        'List Price',
        'Total Value'
      ]
    }
  },
  computed: {},
  created () {},
  mounted () {},
  methods: {
    viewItem (e) {
      let range = this.$refs.dataView.getFilters()

      //  console.log('range', range.warehouse_inventory_date_bottled)

      let temp = {
        warehouse_inventory_finished_product: [
          { id: e.finished_product_id, label: e.Product }
        ],
        warehouse_inventory_date_bottled:
          range.warehouse_inventory_date_bottled,
        startDate: range.startDate,
        endDate: range.endDate
      }

      let filterJSON = JSON.stringify(temp)
      //  console.log('filterJSON', filterJSON)

      this.$router.push({
        name: 'Available products',
        params: {
          filter: filterJSON
        }
      })
    }
  },
  watch: {}
}
</script>

<style></style>
