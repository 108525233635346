var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('DataViewWrapper', {
    ref: "dataView",
    attrs: {
      "filters": _vm.filters,
      "table-columns": _vm.tableColumns,
      "model": _vm.model,
      "slots": ['Product']
    },
    scopedSlots: _vm._u([{
      key: "Product",
      fn: function fn(props) {
        return _c('div', {}, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row['Product']) + " ")])], 1);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }